<template lang="html">
  <div class="">
    <div
    v-if="this.open_lead_box"
      @click="open_lead_box_func(0)"
      class="open_lead_box_dark"
    ></div>
    <div
      v-if="this.shown_image_flag"
      @click="remove_flag_enlarge()"
      class="shown_image_box_dark"
    ></div>

    <div
      v-if="this.shown_image_flag"
      @click="remove_flag_enlarge()"
      class="shown_image_box"   >
      <b-icon icon="x" :style="{marginLeft:'77%', marginTop:'3%', height:'45px', width:'45px'}" @click="remove_flag_enlarge()" />

      <img
        :src="get_image(0, this.shown_image)"
        class="shown_image_box_image"
        alt="image"
      />

    </div>

    <div
      v-if="this.open_lead_box"
      class="open_lead_box"    >
      <div  class="open_lead_box_subdiv"     >

        <div :style="{ marginLeft: '5%', marginTop: '2%' }">
          <div class="lead_box_main" >
            <div class="lead_box_main_header"            >
              Kundförfrågan
            </div>
            <b-icon icon="x" :style="{marginLeft:'37%'}" @click="open_lead_box_func(0)" />
          </div>
          <div
            v-for="(lead, idx) in current_lead"
            :key="idx"
            :style="{ fontSize: '25px', marginLeft: '0%' }"
          >
            <div class="main_comp">
              <div class="label_size">Namn:</div>
              {{ lead.name }}
            </div>
            <div class="main_comp">
              <div class="label_size">Kontaktinfo:</div>
              {{ lead.contact_info }}
            </div>
          </div>
          <div class="box_description">
            <div class="label_size">Beskrivning:</div>
            <textarea
              :style="{
                marginLeft: '2%',
                whiteSpace: 'preWrap',
                resize: 'none',
                width: '100%',
                height: '100%',
              }"
              name="name"
              rows="8"
              cols="80"
              v-model="description"
            >
            </textarea>
          </div>
          <div class="boxes">
            <div class="label_size">Bilder:</div>
            <div
              class="box_flow_images"
              data-toggle="tooltip"
              data-placement="top"
              title="Klicka på för att förstora"
              v-for="(image, idxx) in leads_image_list"
              :key="idxx"
            >
              <img
                :src="get_image(0, image.image)"
                @click="open_enlarge_image_box(image.image)"
                class="show_chosen_image_first"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="this.change_password_var"
      @click="open_change_password_box()"
      class="change_password_var_black"
    ></div>
    <div
      v-if="this.change_password_var"
      class="change_password_var_box"
    >
      <div class="title">Byt lösenord</div>
      <div class="main_comp">
        <!-- <label for="name_input">New password</label> -->
        <input
          type="text"
          name="name_input"
          maxlength="500"
          placeholder="Fyll i nytt lösenord "
          class="input_field_pass"
          v-model="new_password"
        />
      </div>
      <button
        @click="change_password_send()"
        :disabled="this.new_password == ''"
        type="button"
        class="change_password_button"      >
        Byt lösenord
      </button>
    </div>


    <div class="nav_box_large" >
      <div  class="nav_box_side"   >
        <div class="nav_box_side_small"  >
          <div  @click="open_company_info()"
            :class="[this.company_info_var ? 'clicked_button' : 'grey_button']">
            Uppdatera information
          </div>
          <div  @click="open_gear()"
            :class="[this.open_gear_box ? 'clicked_button' : 'grey_button']"
          >
            Inställningar
          </div>
          <div >
            <div
              @click="open_leads()"
              :class="[this.lead_open_var ? 'clicked_button' : 'grey_button']"
            >
              Kundförfrågningar
            </div>
          </div>
        </div>
      </div>

      <div :style="{ width: '100%' }">
        <div
          :style="{
            height: '15px',
            textAlign: 'center',
            marginLeft: '0%',
            marginTop: '2%',
          }"
        >
        </div>
        <div
          v-if="this.open_gear_box"
          :style="{ height: '30px', width: '100%', display: 'flex' }"   >
          <div
            :style="{
              fontSize: '22px',
              height: '10px',
              position: 'absolute',
              fontWeight: '600',
              marginTop: '0%',
              marginLeft: '2%',
              color: '#0A6338',
            }"
          >
            Inställningar
          </div>
        </div>
        <div
          v-if="this.open_gear_box"
          :style="{
            marginTop: '2%',
            textAlign: 'left',
            marginLeft: '3%',
            height: '100px',
            width: '250px',
          }">
          <div @click="open_change_password_box()">Byt lösenord</div>
          <div @click="logout()">Logga ut</div>
          <!-- <div @click="register()" v-if="this.company_id ==49">
            Register new company
          </div>

          <div @click="add_user()" v-if="this.company_id ==49">
            Add user to company
          </div>
          <div @click="see_all_leads()" v-if="this.company_id ==49">
            See all leads
          </div>
          <div @click="analytics()" v-if="this.company_id ==49">
            Analytics
          </div> -->
        </div>
        <div
          :style="{ height: 'auto', marginLeft: '2%', marginTop: '4%', width:'100%' ,paddingBottom:'10%' ,overflowX:'auto'}"
          v-if="lead_open_var">
          <div  class="all_leads_subbox"      >
            <div class="lead_width_header_lg">Namn</div>
            <div class="lead_width_header_lg">
              Kontaktinfo
              <b-icon icon="telephone" />
              /
              <b-icon icon="envelope" />
            </div>
            <div class="lead_width_header">
              Status
              <b-icon icon="filter" @click="open_filter()" />
            </div>
            <div
              v-if="filter_var"
              :style="{
                marginTop: '2%',
                position: 'absolute',
                marginLeft: '44%',
                zIndex: 13,
              }"
            >
              <div
                class="grey_button_small"
                @click="filter(status.id)"
                :key="id"
                v-for="(status, id) in status_list"
              >
                {{ status.status_sve }}
              </div>
            </div>
            <div class="lead_width_header">Info</div>

          </div>
          <div
            v-for="(lead, ip) in filtered_leads"
            :key="ip"
            class="all_leads_subbox"
          >
            <div class="lead_width_namn">
              {{ lead.name }}<br />
              <div :style="{ color: 'grey', fontSize: '11px' }">
                Skapad: {{ lead.created }}
              </div>
              <div :style="{ color: 'grey', fontSize: '11px' }">
                Senast uppdaterad: {{ lead.updated }}
              </div>
            </div>
            <!-- <div class="lead_width">
                Privat
            </div> -->
            <div class="lead_width_namn">
              {{ lead.contact_info }}
            </div>
            <div class="lead_width">
              <div
                @click="open_status(lead)"
                :style="{
                  zIndex: 0,
                  display: 'flex',
                  height: '35px',
                  textAlign: 'center',
                }"
              >
                <div
                  :style="{
                    height: '35px',
                    zIndex: 0,
                    lineHeight: '13px',
                    padding: '0%',
                    paddingTop: '1%',
                    width: 'auto',
                    textAlign: 'center',
                    marginLeft: '30%',
                    marginTop: '2%',
                  }"
                >
                  {{ lead.status }}
                  <b-icon icon="caret-down" />
                </div>
              </div>
              <div
                v-if="lead.status_open_closed == true"
                :style="{
                  zIndex: 13,
                  position: 'relative',
                  opacity: 1,
                  height: '120px',
                }"
              >
                <status
                  :lead_object="lead"
                  :open_status_var="lead.status_open_closed"
                ></status>
              </div>
            </div>
            <div class="lead_width" @click="open_lead_box_func(lead.id)">
              <div
                :style="{
                  textDecoration: 'underline',
                  color: 'blue',
                  cursor: 'pointer',
                  textAlign:'center'
                }"
              >
                Mer info
              </div>
            </div>
            <!-- <b-icon
              icon="x-lg"
              :style="{ marginTop: '1%' }"
              @click="delete_lead(lead.id)"
            /> -->
          </div>
        </div>

        <div  v-if="company_info_var"
          :style="{ height: '80px', width: '100%' }"        >
          <div    class="welcome_div" >
            Välkommen
            {{ this.company_name }}.
          </div>
          <div
            v-if="company_info_var"
            class="company_info_group_comp_info"   >
            <div  class="update_company_info"   >
              Uppdatera ditt företags information
            </div>
            <div
              @click="to_company()"
              class="visit_company_page"        >
              Besök din företagssida
            </div>
          </div>
        </div>


        <div
          v-if="company_info_var"
          class="company_info_group"  >
          <form name="profile">
            <div class="box_main"    >
              <div class="box_main_info"      >
                Din företagsbeskrivning
              </div>
              <div class="box_main_info_em"    >
                <em> Byt företagsbeskrivning </em>
              </div>
              <textarea
                type="text"
                placeholder="Fyll i en kort beskrivning om ditt företag"
                class="input_field_l"
                v-model="company_info"
              />
            </div>
            <div
              class="box_main"  >
              <div class="box_main_info"
              >
                Vad har ni för priser
              </div>
              <div    class="box_main_info_em"  >
                <em> Era priser</em>
              </div>
              <textarea
                type="text"
                placeholder="Fyll i era priser"
                class="input_field_po"
                v-model="price"
              />
            </div>
            <div   class="box_main">
              <div class="box_main_info"              >
                Vad erbjuder ni för tjänster?
              </div>
              <div class="box_main_info_em"   >
                <em> Era tjänster </em>
              </div>
              <textarea
                type="text"
                placeholder="Fyll i era tjänster"
                class="input_field_po"
                v-model="our_services"
              />
            </div>
            <div   class="box_main">
              <div class="box_main_info"   >
                Vad har ni för öppettider?
              </div>
              <div class="box_main_info_em"    >
                <em> Era öppettider </em>
              </div>
              <textarea
                type="text"
                placeholder="Fyll i era öppettider"
                class="input_field_po"
                v-model="our_opening_hours"
              />
            </div>
            <div  class="box_main">
              <div class="box_main_info"   >
                Övrig information
              </div>
              <div class="box_main_info_em"   >
                <em>
                  Fyll i ytterligare information nedan (hemsida, email, adress,
                  postkod, stad, län ni finns i )
                </em>
              </div>
              <div class="input_header">Hemsida:</div>
              <input
                type="text"
                maxlength="500"
                placeholder="Företagets hemsida"
                class="input_field"
                v-model="website"
              />
              <div class="input_header">Email:</div>
              <input
                type="text"
                maxlength="500"
                placeholder="Företagets email"
                class="input_field"
                v-model="contact_email"
              />
              <div class="input_header">Telefon:</div>
              <input
                type="text"
                maxlength="500"
                placeholder="Företagets telefon"
                class="input_field"
                v-model="phone_number"
              />



              <!-- <div class="input_header">Address:</div>
              <input
                type="text"
                maxlength="500"
                placeholder="Address"
                class="input_field"
                v-model="adress"
              /> -->
              <!-- <div class="input_header">Postkod:</div>
              <input
                type="text"
                maxlength="500"
                placeholder="Postkod"
                class="input_field"
                v-model="city_code"
              /> -->
              <!-- <div class="input_header">Stad:</div>
              <input
                type="text"
                maxlength="500"
                placeholder="Stad"
                class="input_field"
                v-model="city"
              /> -->
              <!-- <div class="input_header">Län:</div>
              <input
                type="text"
                class="search_ruta_input"
                v-model="search_lan"
                placeholder="Sök län"
                v-on:keyup="search_lan_func(search_lan)"
              /> -->
              <!-- <div
                :style="{
                  marginTop: '1%',
                  marginLeft: '3%',
                  width: '90%',
                  maxHeight: '415px',
                  overflowY: 'auto',
                  borderStyle: 'None',
                  borderWidth: '0.5px',
                }"
              >
                <div
                  :style="{
                    height: '20px',
                    fontSize: '15px',
                    width: '88%',
                    display: 'flex',
                  }"
                  v-for="(lan, ilx) in this.filtered_lan_list"
                  :key="ilx"
                >
                  <input
                    type="checkbox"
                    :id="'lan' + lan.id"
                    :class="lan.checked ? 'checkbox_filled' : 'checkbox_new'"
                    v-model="lan.checked"
                    @click="choose_lan(lan)"
                  />
                  <label :for="'lan' + lan.id">{{ lan.lan }} </label>
                </div>
              </div> -->
            </div>
            <div class="box_main">
              <div :style="{marginLeft:'4%',color:'#0A6338', fontSize:'18px',paddingTop:'2%'}">
                Var finns ni?
              </div>
              <div class="box_main_info_em">
                <em>
                  Leverar ni till hela landet så klicka i denna box
                 </em>
                 <input type="checkbox" v-model="online_service" class="checkbox_new" value="">
              </div>
              <div class="box_main_info_em">
                <em>Ge information om var ni finns (om ni har butiksadress så fyll gärna i det alternativt fyll i de städer/län där ni är verksamma ) </em>
              </div>

              <input type="text" maxlength="500" placeholder="Address" class="input_field"     v-model="adress" >
              <input type="text" maxlength="500" placeholder="Postkod" class="input_field"    v-model="city_code" >
              <!-- <input type="text" maxlength="500" placeholder="Stad" class="input_field"     v-model="city" > -->


              <input type="text" class="search_ruta_input" v-model="search_lan"
               placeholder="Sök stad"
               v-on:keyup="search_city_func(search_lan)" >
              <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'154px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
                <div  :style="{height:'20px', fontSize:'15px',width:'88%', display:'flex'}"
                v-for="(city,idx) in this.filtered_city_list" :key="idx">
                  <input
                    type="checkbox"
                    :id="'lan'+city.id"
                    :class="city.checked ? 'checkbox_filled' : 'checkbox_new'"
                    v-model="city.checked"
                    @click="choose_city2(city)"
                  />
                  <label :for="'lan'+city.id">{{ city.city }} [ {{city.lan}}] </label>
                </div>
              </div>
              <div :style="{borderStyle:'solid', borderWidth:'1px', borderColor:'black', marginTop:'1%',marginLeft:'4%',width:'90%'}">
              </div>
              <button :style="{marginTop:'2%',marginLeft:'2%'}" @click="add_location()" type="button" name="button">Lägg till </button>
              <div :style="{width:'90%', height:'auto', paddingBottom:'30px' ,marginLeft:'4%', overflowX:'auto'}">
                <div  :style="{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flexStart',
                    fontWeight: 'bold'  }">
                    <div class="lead_width_header_loc">Address</div>
                    <div class="lead_width_header_loc">Postkod</div>
                    <div class="lead_width_header_loc">Kommun</div>
                    <div class="lead_width_header_loc">Län</div>
                </div>
                <div :style="{   display: 'flex',
                    width: '100%',
                    borderBottom: '0.1px grey solid',
                    justifyContent: 'flexStart'}"  v-for="(location,idx) in this.filtered_full_location_list" :key="idx">
                    <!-- <div class="lead_width_left">
                      {{ location.butik }}
                    </div> -->
                    <div class="lead_width_loc">
                      {{ location.adress }}
                    </div>
                    <div class="lead_width_loc">
                      {{ location.city_code }}
                    </div>
                    <div class="lead_width_loc">
                      {{ location.city }}
                    </div>
                    <div class="lead_width_left">
                      {{ location.lan }}
                    </div>
                    <div class="lead_width_loc">
                      <b-icon
                        icon="x-lg"
                        :style="{ marginTop: '1%' }"
                        @click="delete_location(location)"
                      />
                    </div>
                </div>
              </div>
              <div :style="{marginLeft:'3%', fontSize:'14px'}">
              <em>  Swajpa för att se hela adressen </em>
              </div>

            </div>

            <div class="box_main"     >
              <div class="box_main_info">  Kategori
              </div>
              <div class="box_main_info_em">
                <em>
                  Vilken kategori passar din lösning in på? (Markera alla som
                  passar in)
                </em>
              </div>
              <input
                type="text"
                class="search_ruta_input"
                v-model="search_category"
                placeholder="Sök kategori"
                v-on:keyup="search_category_func(search_category)"
              />
              <div v-if="search_category =='' && company_id==1"
                  class="search_category_box">
                         <!-- :class="[ category.category_open    ? 'button_checked' : 'button_unchecked' ]"> -->

                  <div v-for="(category,idx) in huvud_category_list"
                       :key="idx"
                       class="button_unchecked">
                    <div :style="{height:'16px', display:'flex',marginTop:'1%'}"
                          @click="open_kategori(category.id,category.main_category_svenska)">
                      <div :style="{marginLeft:'1%'}">
                        {{category.main_category_svenska}} {{category.total_category_count}}
                      </div>
                    </div>
                    <div  v-if="category.subcategory_opened"
                       :style="{height:'150px',border:0,
                             borderWidth:'0',overflowY:'scroll', boxShadow: '0px 4px 20px rgba(199, 199, 199, 0.6)',
                             borderRadius:'0px 6px 6px 0px',marginTop:'1%',borderColor:'black', opacity:'100%',    zIndex:13,
                             width:'55%',backgroundColor:'white',marginLeft:'9%'}">
                      <div  v-for="(category,idx) in category_list"  :style="{display:'flex'}"   :key="idx" >
                        <input   type="checkbox"  :id="'cat'+category.id"
                          :class="category.checked ? 'checkbox_filled' : 'checkbox_new'"
                          v-model="category.checked"    @click="choose_category(category)"  />
                        <label  :style="{textIndent:'5px'}" :for="'cat'+category.id">{{ category.category }} </label>
                      </div>
                    </div>
                  </div>
              </div>
              <!-- v-if="search_category !='' " -->
              <div
                :style="{
                  marginTop: '1%',
                  marginLeft: '3%',
                  width: '88%',
                  maxHeight: '560px',
                  overflowY: 'auto',
                }"
              >
                <button
                  v-if="!all_selected_category"
                  type="button"
                  name="button"
                  :style="{
                    marginLeft: '1.4%',
                    paddingBottom: '0%',
                    borderStyle: 'None',
                    color: 'white',
                    borderRadius: '8px',
                    backgroundColor: '#0A6338',
                    marginTop: '0%',
                  }"
                  @click="choose_all_category()"
                >
                  Markera alla
                </button>
                <button
                  v-if="all_selected_category"
                  type="button"
                  name="button"
                  :style="{
                    marginLeft: '1.4%',
                    paddingBottom: '0%',
                    borderStyle: 'None',
                    color: 'white',
                    borderRadius: '8px',
                    backgroundColor: '#0A6338',
                    marginTop: '0%',
                  }"  @click="choose_all_category()"  >
                  Avmarkera alla
                </button>
                <div :style="{ height: '5px' }"></div>
                <div :style="{
                    height: '20px',
                    fontSize: '14px',
                    width: '80%',
                    display: 'flex',
                  }"
                  v-for="(category, ixx) in this.filtered_category_list"
                  :key="ixx" >
                  <input
                    type="checkbox"
                    :id="'cat' + category.id"
                    :class="category.checked ? 'checkbox_filled' : 'checkbox_new' "
                    v-model="category.checked"
                    @click="choose_category(category)"
                  />
                  <label
                    :style="{ textIndent: '5px' }"
                    :for="'cat' + category.id"
                    >{{ category.category }}
                  </label>
                </div>
              </div>
            </div>
            <div class="box_main">
              <div  class="box_main_info" >
                Märken ni lagar
              </div>
              <div class="box_main_info_em" >
                <em>Vilka märken jobbar ni med? <br> (om du inte hitter det du söker så lägg gärna till det i listan) </em>
              </div>
                <input type="text" class="search_ruta_input" v-model="search_brand"  placeholder="Sök märke"  v-on:keyup="search_brand_func(search_brand)">

                  <button type="button" :style="{color:'white',marginTop:'1%',height:'auto',width:'auto',marginLeft:'4%',borderStyle:'None',backgroundColor:'#0A6338', borderRadius:'8px'}"
                    @click="add_brand(search_brand)">Lägg till "{{search_brand}}"  i listan</button>

                <div v-if="chosen_brands_list.length !=0" :style="{height:'auto',marginTop:'1%',marginLeft:'3%', flexWrap:'wrap',borderRadius:'0px 18px 18px 18px' ,display:'flex',overflowY:'auto' }">
                    <div  v-for="(brands,idx) in this.chosen_brands_list" :key="idx" class="box_flow_key">
                      <div @click="remove_brand(brands)" :style="{ cursor:'pointer',marginLeft:'92%',width:'50px',height:'7px',marginTop:'0%', fontSize:'12px'}">
                        X
                      </div>
                      <div :style="{textAlign:'center',marginTop:'1%'}">
                        {{brands.brand}}
                      </div>
                    </div>
                </div>

                <div :style="{marginTop:'1%', marginLeft:'3%',width:'90%',maxHeight:'230px', overflowY:'auto', borderStyle:'None', borderWidth:'0.5px'}">
                  <div :style="{height:'20px', fontSize:'14px',width:'100%', display:'flex'}"
                        v-for="(brand,idx) in this.filtered_brands_list" :key="idx">
                    <input  type="checkbox"  :id="'key'+brand.id"
                      :class="brand.checked ? 'checkbox_filled' : 'checkbox_new'"
                      v-model="brand.checked" @click="choose_brand(brand)"/>
                    <label :style="{textIndent:'5px'}" :for="'key'+brand.id">{{ brand.brand }}</label>
                  </div>
                </div>
            </div>

          <div class="box_main_image"      >
            <div  :style="{ marginLeft: '4%', fontSize: '20px', paddingTop: '0%' }"  >
              Byt logga på ditt företag
            </div>
            <img
              id="output"
              :src="get_image(this.company_id, this.image)"
              class="show_chosen_image"
            />
            <label for="upload" :style="{ marginLeft: '52%' }">
              <img
                :src="`${publicPath}` + 'upload.png'"
                class="addphoto_camera"
                alt=""
              />
            </label>
            <input
              type="file"
              :style="{ marginLeft: '15%' }"
              id="upload"
              class="custom-file-upload"
              @change="upload_picture"
            />
          </div>

          <div class="multiple-image-upload_main">
           <div class="multiple-image-upload__header">
            <div class="multiple-image-upload__headline">
              Ladda upp bilder till ditt galleri
            </div>
           </div>
            <multiple-image-upload
              v-model="imagesToUpload"
              :gallery-list="gallery_list"
              @update:gallery-images="get_gallery_list"
            />
          </div>
          </form>
          <button @click="update_product()" class="register_button_clicked">
            Uppdatera information
          </button>
          <div :style="{ height: '90px' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import router from "../router/index";
// import Navigation from "../components/Navigation.vue";
import { API_URL } from "../../config.js";
import axios from "axios";
import { bus } from "../main";
import status from "../components/status.vue";
import MultipleImageUpload from "@/components/multiple_image_upload.vue";

export default {
  data: function () {
    return {
      publicPath: process.env.BASE_URL + "",
      gallery_list: [],
      imagesToUpload: [],
      leads_image_list: [],
      full_location_list:[],
      filtered_city_list:[],
      city_list:[],
      current_user: {},
      lead_open_var: false,
      company_info_var: true,
      filter_var: false,
      company_name: "",
      our_opening_hours: "",
      leads: [],
      status_list: [],
      company_id: 0,
      company_info: "",
      website: "",
      contact_email: "",
      phone_number: "",
      price: "",
      our_services: "",
      adress: "",
      city_code: "",
      image: "",
      city: "",
      shown_image: "",
      chosen_country: [],
      chosen_category_list: [],
      isActive: false,
      search_country: "",
      filtered_country_list: "",
      country_list: [],

      all_selected_category: false,

      filtered_full_location_list:[],

      products_list: [],
      category_box_opened: false,
      choose_category_list: [],
      filtered_category_list: [],
      category_list: [],
      search_category: "",

      huvud_category_list:[],

      info_box_opened: false,
      filtered_leads: [],
      selectedFile: "",
      current_image: false,
      description: "",
      chosen_lan_list: [],
      filtered_lan_list: [],
      search_lan: "",
      open_lead_box: false,
      open_gear_box: false,
      change_password_var: false,
      loading: false,
      new_password: "",
      current_lead: [],
      chosen_lan: "",
      online_service:false,
      shown_image_flag: false,
      city_filter_list:[],
      chosen_brands_list:[],
      checked_brand_id_list:[],
      search_brand:'',
      filtered_brands_list:[],
      brand_query_max:0,
      brands_list:[],
      location_query_max:0
    };
  },
  methods: {
    open_enlarge_image_box(image) {
      this.shown_image_flag = true;
      this.shown_image = image;
    },
    remove_flag_enlarge() {
      this.shown_image_flag = false;
    },
    choose_city2(city_object){
      var checked
      if(!city_object.checked){
        checked =true
        this.city = city_object.city
        this.lan = city_object.lan
        this.lan_id = city_object.lan_id


        // this.search_lan = lan.name
        // this.chosen_lan = [{"lan_id":lan.id,"lan":lan.name}]
      }
      else {
        checked=0
//        this.chosen_lan = []
      }
        for (let ii = 0; ii < this.city_list.length;ii++){
          this.city_list[ii].checked =  false
      }
      this.city_list[city_object.i].checked =  checked
    },


    post_to_S3_with_signed_url(){
      var key ="enjordrepair_user"+this.company_id +"/" +  this.image
      var type =this.selectedFile.type


      var self = this
      var params = {
          Bucket: 'garemotest1',
          Key: key,
          "ContentType":type
      };
     self.get_AWS().getSignedUrl('putObject', params, function (err, url) {

      axios({
          method: "put",
          url,
          data: self.selectedFile,
          headers: {
              "Content-Type": type
          }
      })
      .then((response) => {
            console.log(response);
              //self.current_image =false
          }).catch((err) => {
              console.log('err', err);
          });
      });
    },


    load_brands(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_brands_profile'+'?company_id='+this.company_id,
        })
        .then(function(response){
            self.brands_list = response.data["show_brand_list"]
            self.brand_query_max =response.data["brand_query_max"]

            self.chosen_brands_list =  response.data["chosen_brands_list"]
            self.checked_brand_id_list = response.data["checked_brand_id_list"]


            self.filtered_brands_list= self.brands_list.map(brand =>{
                  if (self.checked_brand_id_list.includes(brand.id) ===true ){
                      brand.checked = true
                  }
                      return brand
              })

      })
    },
    search_brand_func(search_brand){
          this.filtered_brands_list =  this.brands_list.filter(
            brand_object =>  brand_object.brand.toLowerCase().includes(search_brand.toLowerCase())
          )
    },
    add_brand(brand){
      var i =this.brand_query_max
      this.brand_query_max = this.brand_query_max + 1

      var new_brand = {"i":i,"id":this.brand_query_max ,"brand":brand,"checked":true ,'added':true, 'changed':true }
      this.chosen_brands_list.push(new_brand)
      this.brands_list.push(new_brand)
      //this.choose_brand(brands_obj)
    },
    remove_brand(brands_obj){
      // function removeItem(arr, id){
      //     return arr.filter(item => item.id !== id);
      // }
      // this.brand_query_max = this.brand_query_max - 1
      // this.chosen_brands_list = removeItem(  this.chosen_brands_list, brands_obj.id);

      this.choose_brand(brands_obj)
      // this.brands_list = this.brands_list.map(brand => {
      //     if (brand.id == brands_obj.id){
      //         brand.added =  false
      //         brand.changed =  false
      //     }
      //         return brand
      //   })

    },
    choose_brand(brand_object){
        this.brands_list = this.brands_list.map(brand => {
            if (brand.id == brand_object.id){
                brand.checked = brand.checked === false ? true : false
                brand.changed=true
            }
                return brand
          })

          this.chosen_brands_list = this.brands_list.filter(function (brand) {
            if(brand.checked == true) {
              return brand
            }
          })
    },
    removeItem(arr,id){
      // function removeItem(arr, id){
          return arr.filter(item => item.id !== id);
//      }

    },
    delete_location(location_obj){

      // if(location_obj.added == true){
      //   this.location_query_max = this.location_query_max - 1
      //
      // }

      this.full_location_list = this.full_location_list.map(location => {
          if (location.id == location_obj.id){
              location.checked =  false
              location.changed = true
              location.added = false
          }
              return location
        })


        this.check_location()

       //this.filtered_full_location_list = this.removeItem(  this.filtered_full_location_list, location_obj.id);


        // var self = this;
        // axios({
        //   method: "delete",
        //   url: API_URL + "/delete_location",
        //   data: {
        //     id: location_obj.id
        //   },
        // }).then(function () {
        //   self.get_company_information();
        // });
      // }

    },
    add_location(){

      var i =this.location_query_max
      this.location_query_max = this.location_query_max + 1

      var new_location = {"i":i,"id":this.location_query_max ,'added':true,'changed':true , 'checked':true ,"adress": this.adress , "city_code":this.city_code, "city":this.city, "lan":this.lan, "lan_id":this.lan_id }
      this.full_location_list.push(new_location)
      this.check_location()
    },
    check_location(){
      this.filtered_full_location_list = this.full_location_list.filter(function (location) {
        if(location.checked == true) {
          return location
        }
      })

    },
    load_cities(){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/get_cities',
        })
        .then(function(response){
            self.city_list = response.data
            self.filtered_city_list =self.city_list
      })
    },
    register() {
      router.push({ name: "register" });
    },
    remove_from_gallery_loaded(image_id, image_name) {
      var self = this;
      axios({
        method: "delete",
        url: API_URL + "/delete_gallery_image",
        data: {
          image_id: image_id,
          image: image_name,
          company_id: this.company_id,
        },
      }).then(function () {
        self.get_gallery_list();
      });
    },
    see_all_leads() {
      router.push({ name: "see_all_leads" });
    },
    choose_main_category(category){
      var self = this
      axios({
          method:'get',
          url: API_URL+ '/choose_main_category'+'?category='+category+'&company_id='+this.company_id,
        })
        .then(function(response){
            self.category_list=response.data
        })

    },
    open_info_box() {
      if (this.info_box_opened) {
        this.info_box_opened = false;
      } else {
        this.info_box_opened = true;
      }
    },
    search_city_func(search_lan){
        this.filtered_city_list =  this.city_list.filter(
          city_object =>  city_object.city.toLowerCase().includes(search_lan.toLowerCase())
        )
    },
    analytics() {
      router.push({ name: "analytics" });
    },

    add_user() {
      router.push({ name: "create_user" });
    },
    open_change_password_box() {
      if (this.change_password_var) {
        this.change_password_var = false;
      } else {
        this.change_password_var = true;
      }
    },
    open_kategori(id,huvud_kategori){
      this.choose_main_category(huvud_kategori)

      for (let ii = 0; ii < this.huvud_category_list.length;ii++){
                this.huvud_category_list[ii].subcategory_opened =  false
            }
          this.huvud_category_list[id].subcategory_opened = true
    },
    get_main_categories(){
        var self = this
        axios({
            method:'get',
            url: API_URL+ '/get_main_categories_profile'+'?company_id='+this.company_id,
          })
          .then(function(response){
              self.huvud_category_list = response.data
          })
    },
    open_company_info() {
      this.company_info_var = true;
      this.lead_open_var = false;
      this.open_gear_box = false;
    },
    open_gear() {
      this.open_gear_box = true;
      this.company_info_var = false;
      this.lead_open_var = false;
    },
    open_leads() {
      this.lead_open_var = true;
      this.open_gear_box = false;
      this.company_info_var = false;
    },

    removeImageFromGallery(imageId) {
      this.imagesToUpload = this.imagesToUpload.filter(
        (currentElement) => currentElement.id !== imageId
      );
    },

    upload_picture(event) {
      this.selectedFile = event.target.files[0]
      this.isActive=true
      setTimeout(() => {
        var image = document.getElementById('output');
        image.src = URL.createObjectURL(event.target.files[0]);
    }, 1000);
    var posttype_ending = this.selectedFile.type.split("/")[1]
    if (this.selectedFile.type.split("/")[1]  =='jpeg' && this.selectedFile.name.includes('jpg')){
      this.image=this.selectedFile.lastModified +this.selectedFile.name.split("jpg")[0]  + 'jpeg'
    }
    else{
      this.image=this.selectedFile.lastModified +this.selectedFile.name.split(posttype_ending.toString())[0]  + posttype_ending.toString()
    }

    console.log("this.image", this.image);

      // this.selectedFile = event.target.files[0];
      // this.isActive = true;
      // setTimeout(() => {
      //   var image = document.getElementById("output");
      //   image.src = URL.createObjectURL(event.target.files[0]);
      // }, 1000);
      // var posttype_ending = this.selectedFile.type.split("/")[1];
      // if (
      //   this.selectedFile.type.split("/")[1] == "jpeg" &&
      //   this.selectedFile.name.includes("jpg")
      // ) {
      //   this.image =
      //     this.selectedFile.lastModified +
      //     this.selectedFile.name.split("jpg")[0] +
      //     "jpeg";
      // } else {
      //   this.image =
      //     this.selectedFile.lastModified +
      //     this.selectedFile.name.split(posttype_ending.toString())[0] +
      //     posttype_ending.toString();
      // }
    },
    async upload_picture_multiple(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const currentFile = event.target.files[i];
        const fileSubtype = currentFile.type.split("/")[1];

        let imageToUpload;
        // normalizing the file name to jpeg
        if (fileSubtype === "jpeg" && currentFile.name.includes("jpg")) {
          const filenameWithoutExtension = currentFile.name.split("jpg")[0];
          imageToUpload =
            currentFile.lastModified + filenameWithoutExtension + "jpeg";
        } else {
          imageToUpload =
            currentFile.lastModified +
            currentFile.name.split(fileSubtype.toString())[0] +
            fileSubtype.toString();
        }

        this.imagesToUpload.push({
          id: this.imagesToUpload.length + i,
          file: currentFile,
          output:
            "output" + this.imagesToUpload.length.toString() + i.toString(),
          image: imageToUpload,
        });
        await this.post_to_S3_with_signed_url_multiple(
          currentFile,
          imageToUpload
        );
      }

      /*for (var i = 0; i < this.imagesToUpload.length; i++) {
        console.log(this.imagesToUpload[i]["output"]);
        //var image = document.getElementById(this.temp_gallery_list['output']);
        document.getElementById(this.imagesToUpload[i]["output"]).src =
          URL.createObjectURL(event.target.files[i]);
      }*/
    },
    async post_to_S3_with_signed_url_multiple(file, image) {
      const key = "enjordrepair_user" + this.company_id + "/" + image;
      const type = file.type;

      const params = {
        Bucket: "garemotest1",
        Key: key,
        ContentType: type,
      };

      const url = await this.get_AWS().getSignedUrlPromise("putObject", params);
      try {
        await axios({
          method: "put",
          url,
          data: file,
          headers: {
            "Content-Type": type,
          },
        });
        //self.current_image =false
      } catch (e) {
        console.error(e);
      }
    },
    open_lead_box_func(lead_id) {
      if (this.open_lead_box) {
        this.open_lead_box = false;
      } else {
        this.open_lead_box = true;
        this.get_lead(lead_id);
      }
    },
    get_lead(lead_id) {
      var self = this;
      axios({
        method: "get",
        url: API_URL + "/get_lead" + "?lead_id=" + lead_id,
      }).then(function (response) {
        self.current_lead = response.data["show_lead_list"];
        self.description = response.data["show_lead_list"][0]["description"];
        self.leads_image_list = response.data["image_list"];
      });
    },
    get_image(id, image) {
      if (image) {
        var data = "enjordrepair_user" + id + "/" + image;
        return this.$func.get_image(data);
      }
    },
    remove_from_product_list(id) {
      // function removeItem(arr, id){
      //     return arr.filter(item => item.id !== id);
      // }
      // this.shown_products_list = removeItem(  this.shown_products_list, id)

      this.products_list = this.products_list.map((product) => {
        if (product.id == id) {
          product.active = 0;
        }
        return product;
      });
    },
    logout() {
      this.$store.commit("set_current_user", {
        user_id: 0,
        message: "",
        token: "",
        username: "",
      });
      router.push({ name: "explore_page" });
    },
    update_product() {
      var general = {};
      general["company_name"] = this.company_name;
      general["company_info"] = this.company_info;
      general["website"] = this.website;
      general["contact_email"] = this.contact_email;
      general["phone_number"] = this.phone_number;

      general["price"] = this.price;
      general["our_services"] = this.our_services;
      general["our_opening_hours"] = this.our_opening_hours;
      general["online_service"] = this.online_service

      var location ={}


      var send_locations_list = this.full_location_list.filter(function (location) {
        if(location.changed == true) {
          return location
        }
      })

      location["adress_list"]  =send_locations_list

      general["page"] = "";


      this.chosen_country = this.country_list.filter(function (country_obj) {
        if (country_obj.checked == true) {
          return country_obj;
        }
      });

      general["country"] = this.chosen_country;
      general["image"] = this.image;

      this.chosen_category_list = this.category_list.filter(function (
        category_obj
      ) {
        if (category_obj.changed == true) {
          return category_obj;
        }
      });
      var category = {};
      category["category"] = this.chosen_category_list;

      // var lan = {};
      // lan["lan"] = this.chosen_lan_list;

      var send_brands_list = this.brands_list.filter(function (brand) {
        if(brand.changed == true) {
          return brand
        }
      })

      var brands ={}
      brands["brands"] = send_brands_list


      var self = this;
      axios({
        method: "put",
        url: API_URL + "/updateCompany",
        data: {
          company_id: this.company_id,
          general: general,
          category: category,
          location:location,
          brands:brands,
          gallery_list: this.imagesToUpload,
        },
      }).then(function () {
        if (self.isActive) {
          self.post_to_S3_with_signed_url();
        }
        // self.$alert("Your company profile has been updated")
        self.$alert("Din företagssida har blivit uppdaterad");
      });



    },
    to_company() {
      router.push({ path: "company_page", query: { id: this.company_id } });
    },
    search_category_func(search_category) {
      this.filtered_category_list = this.category_list.filter(
        (category_object) =>
          category_object.category
            .toLowerCase()
            .includes(search_category.toLowerCase())
      );
    },
    search_lan_func(search_lan) {
      this.filtered_lan_list = this.lan_list.filter((lan_object) =>
        lan_object.name.toLowerCase().includes(search_lan.toLowerCase())
      );
    },
    search_country_func(search_country) {
      this.filtered_country_list = this.country_list.filter((country_object) =>
        country_object.country
          .toLowerCase()
          .includes(search_country.toLowerCase())
      );
    },
    choose_category(category_object) {
      this.category_list = this.category_list.map((category) => {
        if (category.id == category_object.id) {
          category.checked = category.checked === false ? true : false;
          category.changed = true;
        }
        return category;
      });
    },
    choose_all_category() {
      if (!this.all_selected_category) {
        this.all_selected_category = true;
        this.category_list = this.category_list.map((category) => {
          category.checked = true;
          category.changed = true;
          return category;
        });
      } else {
        this.all_selected_category = false;
        this.category_list = this.category_list.map((category) => {
          category.checked = false;
          category.changed = true;
          return category;
        });
      }
    },
    choose_lan(lan_object) {
      this.lan_list = this.lan_list.map((lan) => {
        if (lan.id == lan_object.id) {
          lan.checked = lan.checked === false ? true : false;
          lan.changed = true;
        }
        return lan;
      });
    },
    choose_lan2(lan) {
      var checked;
      if (!lan.checked) {
        checked = true;
        this.search_lan = lan.name;
        this.chosen_lan_id = lan.id;
        this.chosen_lan = lan.name;
      } else {
        checked = 0;
        this.chosen_lan = "";
      }
      for (let ii = 0; ii < this.lan_list.length; ii++) {
        this.lan_list[ii].checked = false;
      }
      this.lan_list[lan.i].checked = checked;
    },
    choose_country(country) {
      var checked;
      if (!country.checked) {
        checked = true;
        this.search_country = country.country;
        this.chosen_country = [{ id: country.id, country: country.country }];
      } else {
        checked = 0;
        this.chosen_country = [];
      }
      for (let ii = 0; ii < this.country_list.length; ii++) {
        this.country_list[ii].checked = false;
      }
      this.country_list[country.i].checked = checked;
    },
    add_more_to_product_list() {
      this.products_list_id = this.products_list_id + 1;
      this.products_list.push({
        id: this.products_list_id,
        product_id: 0,
        active: 1,
        list: [
          { name: "", label: "Name", label_sve: "Namn" },
          { name: "", label: "Description", descr_sve: "Beskrivning" },
        ],
      });
    },
    get_company_information_categories() {
      var self = this;
      axios({
        method: "get",
        url:
          API_URL +
          "/get_company_information_categories" +
          "?company_id=" +
          this.company_id,
      }).then(function (response) {
        self.category_list = response.data["show_category_list"];
        self.checked_category_id_list =
          response.data["checked_category_id_list"];
        self.filtered_category_list = self.category_list.map((category) => {
          if (self.checked_category_id_list.includes(category.id) === true) {
            category.checked = true;
          }
          return category;
        });
      });
    },
    open_filter() {
      if (this.filter_var) {
        this.filter_var = false;
      } else {
        this.filter_var = true;
      }
    },
    filter(state_id) {
      if (state_id == 5) {
        this.filtered_leads = this.leads;
      } else {
        this.filtered_leads = this.leads.filter(function (lead) {
          if (lead.id == state_id) {
            return lead;
          }
        });
      }
      this.filter_var = false;
    },
    delete_lead(lead_id) {
      var self = this;
      axios({
        method: "delete",
        url: API_URL + "/delete_lead",
        data: { lead_id: lead_id },
      }).then(function () {
        //         self.$alert("Are you sure you want to delete this lead?").then(() => {
        // self.$alert("Är du säker på att du vill radera detta lead?").then(() => {
        self.get_my_leads();
        // })
      });
    },
    choose_status(lead_object, new_status) {
      this.leads = this.leads.map((lead) => {
        if (lead.id == lead_object.id) {
          lead.status = new_status;
        }
        return lead;
      });
      this.open_status(lead_object);
      this.update_leads();
    },
    open_status(lead_object) {
      this.leads = this.leads.map((lead) => {
        if (lead.id == lead_object.id) {
          lead.status_open_closed =
            lead.status_open_closed === false ? true : false;
        }
        return lead;
      });
    },
    update_leads() {
      //  var self = this
      axios({
        method: "put",
        url: API_URL + "/update_lead",
        data: { company_id: this.company_id, leads: this.leads },
      }).then(function () {
        //self.$alert("Your leads have been updated")
      });
    },
    get_company_information() {
      //    this.loading = true
      var self = this;
      axios({
        method: "get",
        url:
          API_URL +
          "/get_company_information_profile" +
          "?company_id=" +
          this.company_id +
          "&page=" +
          "profile",
        headers: { "x-access-token": this.$store.state.current_user.token },
      }).then(function (response) {
        var res = response.data["message"];

        //TOKEN svar från backend för att kolla om token har gått ut eller inte
        if (res == "failed") {
          self.$store.commit("set_current_user", { user_id: 0 });
          router.push({ name: "explore_page" });
          //self.check_token('failed')
        } else {
          self.name = response.data["show_company_info"]["name"];
          self.image = response.data["show_company_info"]["image"];
          self.current_image = true;
          self.company_info = response.data["show_company_info"]["about"];

          self.website = response.data["show_company_info"]["website"];
          self.company_type =
            response.data["show_company_info"]["company_type"];
          //self.products_list = response.data["show_product_info"];
          self.company_name = response.data["show_company_info"]["name"];
          //self.products_list_id = response.data["show_product_info"];
          // self.adress = response.data["show_company_info"]["adress"];
          // self.city_code = response.data["show_company_info"]["city_code"];
          // self.city = response.data["show_company_info"]["company_city"];
          self.full_location_list =response.data["show_location_list"]
          self.filtered_full_location_list =self.full_location_list

          self.location_query_max =response.data["location_query_max"]


          self.phone_number = response.data["show_company_info"]["phone_number"];
          self.contact_email = response.data["show_company_info"]["contact_email"];
          self.price = response.data["show_company_info"]["price"];
          self.our_services = response.data["show_company_info"]["our_services"];
          self.our_opening_hours = response.data["show_company_info"]["our_opening_hours"];
          self.online_service = response.data["show_company_info"]["online_service"]

          self.get_gallery_list();
          //self.get_countries()
          self.get_company_information_categories();
          //self.get_company_information_lan();
        }
      });
    },
    get_gallery_list() {
      var self = this;
      axios({
        method: "get",
        url: API_URL + "/get_gallery_images" + "?company_id=" + this.company_id,
      }).then(function (response) {
        self.gallery_list = response.data;
      });
    },
    get_company_information_lan() {
      var self = this;
      axios({
        method: "get",
        url:
          API_URL +
          "/get_company_information_lan" +
          "?company_id=" +
          this.company_id,
      }).then(function (response) {
        // self.lan_list = response.data["show_lan_check_list"]
        // self.filtered_lan_list =self.lan_list
        self.lan_list = response.data["show_lan_list"];
        self.checked_lan_id_list = response.data["checked_lan_id_list"];

        self.filtered_lan_list = self.lan_list.map((lan) => {
          if (self.checked_lan_id_list.includes(lan.id) === true) {
            lan.checked = true;
          }
          return lan;
        });
        self.chosen_lan_list = response.data["show_lan_check_list"];
      });
    },
    get_countries() {
      var self = this;
      axios({
        method: "get",
        url:
          API_URL + "/get_profile_countries" + "?company_id=" + this.company_id,
      }).then(function (response) {
        self.filtered_country_list = response.data["show_countries_list"];

        self.country_list = self.filtered_country_list.map((country) => {
          if (country.id == response.data["checked_country_id"]) {
            country.checked = true;
          }
          return country;
        });
        self.search_country_list = self.country_list.filter(function (
          company_size_obj
        ) {
          if (company_size_obj.checked == true) {
            return company_size_obj.country;
          }
        });
        self.search_country = self.search_country_list[0].country;
      });
    },
    change_password_send() {
      var self = this;
      axios({
        method: "put",
        url: API_URL + "/change_password",
        data: {
          userid: this.current_user.user_id,
          email: "",
          password: this.new_password,
          page: "changepassword",
        },
      }).then(function () {
        //         self.$alert("Your password has now been changed")
        self.$alert("Ditt lösenord har ändrats");
        self.change_password_var = false;
      });
    },
    get_my_leads() {
      var self = this;
      axios({
        method: "get",
        url: API_URL + "/get_my_leads" + "?company_id=" + this.company_id,
      }).then(function (response) {
        self.leads = response.data["show_lead_list"];
        self.filtered_leads = self.leads;
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.current_user = this.$store.state.current_user;
    this.company_id = this.current_user.company_id;
    this.get_my_leads();
    this.get_company_information();
    this.load_cities()
    bus.$on("choose-status", (object) => {
      this.choose_status(object["lead_object"], object["new_status"]);
    });

    this.load_brands()

    this.get_main_categories()
    this.status_list = [
      { i: 1, id: 0, status: "New", status_sve: "Ny" },
      { i: 2, id: 1, status: "Contacted", status_sve: "Kontaktad" },
      {
        i: 3,
        id: 2,
        status: "Waiting for reply",
        status_sve: "Väntar på svar",
      },
      { i: 4, id: 3, status: "Cancelled", status_sve: "Avbruten" },
      { i: 5, id: 4, status: "Completed", status_sve: "Avslutad" },
      { i: 6, id: 5, status: "Clear filter", status_sve: "Se allt" },
    ];

    this.product_list = [
      {
        id: 1,
        list: [
          { name: "", label: "Name", label_sve: "Namn" },
          { name: "", label: "Description", descr_sve: "Beskrivning" },
        ],
      },
    ];
  },
  components: {
    MultipleImageUpload,
    // Navigation,
    status,
  },
};
</script>

<style lang="css" scoped>

@media (min-width:450px ){

  .open_lead_box_dark{
    width: 100%;
    z-Index: 2;
    background-Color: black;
    opacity: 0.5;
    position: fixed;
    margin-Left: 0%;
    margin-Top: 0%;
    height: 100%;
  }

  .change_password_var_box{
    width: 60%;
    text-Align: left;
    z-Index: 6;
    position: fixed;
    margin-Left: 14%;
    margin-Top: 6%;
    height: 42%;
    background: #FFFFFF;
    border-Radius: 30px;
  }


  .change_password_button{
    color: white;
    padding-Top: 1%;
    margin-Left: 70%;
    margin-Top: 2%;
    font-Size: 16px;
    width: 160px;
    height: 35px;
    border-Style: None;
    border-Radius: 8px;
    background-Color: #0A6338;
  }


  .welcome_div{
      font-Size: 22px;
      height: 40px;
      font-Weight: 600;
      margin-Top: 0%;
      margin-Left: 1%;
      color: #0A6338;
      text-align: left;

    }


  .change_password_var_black{
    width: 100%;
    z-Index: 2;
    background-Color: black;
    opacity: 0.5;
    position: fixed;
    margin-Left: 0%;
    margin-Top: 0%;
    height: 100%;
  }




  .all_leads_subbox{
      display: flex;
      width: 80%;
      justify-Content: flex-Start;
      border: 0.1px grey solid;
      font-Weight: bold;
    }


  .shown_image_box{
    width: 90%;
    z-Index: 9;
    position: fixed;
    margin-Left: 4%;
    margin-Top: 2%;
    height: 72%;
    background: #FFFFFF;
    border-Radius: 30px;
  }

  .nav_box_large{
    display: flex
  }


  .nav_box_side{
    width: 220px;
    height: 220px;
    box-Shadow: 0px 0px 30px #0000001A;
  }

  .nav_box_side_small{
      margin-Left: 3%;
      margin-Top: 15%;
  }

  .visit_company_page{
      color: #0A6338;
      cursor: pointer;
      text-Decoration: underline;
      position: absolute;
      margin-Left: 57%;
  }


  .grey_button {
    height: 60px;
    font-size: 15px;
    width: 170px;
    padding-top: 7%;
    color: black;
    background: white;
  }

  .clicked_button {
    color: white;
    height: 60px;
    width: 170px;
    font-size: 15px;
    padding-top: 7%;
    border-radius: 8px;
    background-color: #0A6338;
  }


  .update_company_info{
    font-Size: 18px;
    height: 10px;
    position: absolute;
    font-Weight: 600;
    margin-Top: 0%;
    margin-Left: 0.7%;
    color: #0A6338;
  }

  .box_main{
    width: 99%;
    margin-Left: 5%;
    height: auto;
    margin-Top: 2%;
    padding: 3%;
    border-Radius: 18px;
    background-Color: white;
    box-Shadow: 0px 3px 30px #0000001A;

  }


  .company_info_group{
    height: 40px;
    width: 80%;
    margin-Left: 1.5%;
    text-Align: left;
  }

  .company_info_group_comp_info{
    margin-Top: 0%;
    height: auto;
    display: flex;
    text-Align: left;
    margin-Left: 0%;
    width: 80%;
  }

  .box_main_info{
      margin-Left: 4%;
      font-Size: 18px;
      padding-Top: 0%;
      color: #0A6338;
    }



     .box_main_info_em{
       height:auto;
       font-Size:13px;
        margin-Left:4%;
        display:flex;
         color:black;
     }

     .input_header {
       margin-left: 4%;
       margin-top: 1%;
       height: 10px;
       color: black;
     }

     .show_chosen_image {
       height: 70px;
       border-radius: 8px;
       width: 70px;
       display: flex;
       object-fit: contain;
       position: absolute;
       background-color: white;
     }

     .register_button {
       color: white;
       margin-left: 25%;
       margin-top: 2%;
       font-size: 22px;
       height: 50px;
       width: 40%;
       border-style: None;
       border-radius: 8px;
       background-color: grey;
     }

     .register_button_clicked {
       color: white;
       margin-left: 25%;
       margin-top: 4%;
       font-size: 22px;
       height: 50px;
       width: 40%;
       border-style: None;
       border-radius: 8px;
       background-color: #0A6338;
     }

     .open_lead_box{
       width: 90%;
       z-Index: 6;
       position: fixed;
       margin-Left: 4%;
       margin-Top: 1%;
       height: 92%;
       background: #FFFFFF;
       border-Radius: 30px;
     }

     .open_lead_box_subdiv{
         height: auto;
         width: 100%;
         font-Style: normal;
         font-Weight: 500;
         font-Size: 55px;
       }


     .lead_box_main{
      height: 70px;
      width: 100%;
       display: flex;
     }

     .lead_box_main_header{
       font-Size: 49px;
       height: 10px;
       text-Align: center;
       font-Weight: 700;
       margin-Top: 0%;
       margin-Left: 40%;
       color: #0b7443;
     }

     .label_size {
       width: 150px;
       font-weight: 600;
       text-align: left;
     }

     .box_description {
       height: 210px;
       padding-bottom: 0.2%;
       display: flex;
       margin-top: 1%;
       width: 80%;
       font-size: 25px;
     }

     .lead_width_header_lg {
       width: 210px;
       height: 30px;
       font-size: 21px;
       /* border: 0.1px Solid grey; */
     }


     .lead_width_header {
       width: 210px;
       height: 30px;
       font-size: 21px;
       /* border: 0.1px Solid grey; */
     }

     .lead_width_namn {
       text-align: center;

       width: 210px;
       height: 90px;
       font-size: 18px;
       padding-top: 0.5%;
       /* text-align:left;
       padding-left: 2%; */
       /* border: 0.5px Solid; */
     }

     .lead_width {
       width: 210px;
       height: 90px;
       font-size: 18px;
       padding-top: 0.5%;
       text-align: center;
       /*padding-left: 2%; */
       /* border: 0.5px Solid; */
     }


     .main_comp {
       display: flex;
       width: auto;
       margin-left: 0%;
       margin-top: 1%;
     }


     .shown_image_box_image{
       height: 90%;
       margin-Top: 4%;
       width: 50%;
     }


     .box_main_image{
       width:99%;
       margin-Left: 5%;
       height:auto;
       margin-Top:2%;
       border-Radius:18px;
       background-Color:white;
       box-Shadow: 0px 3px 30px #0000001A;
       padding: 3%;
       padding-bottom: 15%;
     }

     .multiple-image-upload_main {
       width: 99%;
       margin-left: 5%;
       height: auto;
       margin-top: 2%;
       border-radius: 18px;
       background-color: white;
       box-shadow: 0 3px 30px #0000001a;
       padding-bottom: 10px;
     }


     .search_category_box{
       height:250px;
       border:0;
       position:absolute;
       border-Width:0;
      overflow-Y:auto;
      box-Shadow: 0px 4px 20px rgba(199, 199, 199, 0.6);
      border-Radius:6px 0px 0px 6px;
      margin-Top:2%;
      opacity:100%;
      z-Index:13;
      width:45%;
      line-Height:14px;
      background-Color:white;
      margin-Left:3%;
    }

}

@media (max-width:450px ){

  .change_password_var_black{
    width: 100%;
    z-Index: 2;
    background-Color: black;
    opacity: 0.5;
    position: fixed;
    margin-Left: 0%;
    margin-Top: 0%;
    height: 100%;
  }


  .change_password_var_box{
    width: 90%;
    text-Align: left;
    z-Index: 6;
    position: fixed;
    margin-Left: 4%;
    margin-Top: 6%;
    height: 42%;
    background: #FFFFFF;
    border-Radius: 30px;
  }

  .welcome_div{
      font-Size: 22px;
      height: 30px;
      font-Weight: 600;
      margin-Top: 0%;
      margin-Left: 1%;
      color: #0A6338;
      text-align: left;

    }


  .change_password_button{
    color: white;
    padding-Top: 1%;
    margin-Left: 50%;
    margin-Top: 2%;
    font-Size: 16px;
    width: 160px;
    height: 35px;
    border-Style: None;
    border-Radius: 8px;
    background-Color: #0A6338;
  }




  .lead_box_main{
   height: 40px;
   width: 98%;
    display: flex;
  }

  .lead_box_main_header{
    font-Size: 25px;
    height: 10px;
    text-Align: center;
    font-Weight: 700;
    margin-Top: 0%;
    margin-Left: 0%;
    color: #0b7443;

  }


  .nav_box_large{
  }

  .nav_box_side{
    width:100%;
    display: flex;
    height: auto;
    /* box-Shadow: 0px 0px 30px #0000001A; */
  }


  .all_leads_subbox{
      display: flex;
      /* width: 98%; */
      min-width: 700px;
      max-width: 700px;
      justify-Content: flex-Start;
      border: 0.1px grey solid;
      font-Weight: bold;
    }

    .lead_width_header_lg {
      max-width: 200px;
      min-width: 200px;
      height: 40px;
      /* padding-left: 5%; */
      text-align: center;
      font-size: 15px;
    }



    .lead_width_header {
      max-width: 120px;
      min-width: 120px;
      height: 40px;
      /* padding-left: 5%; */
      text-align: center;
      font-size: 15px;
    }


  .nav_box_side_small{
    display: flex;
      margin-Left: 3%;
  }


  .open_lead_box_dark{
    width: 100%;
    z-Index: 2;
    background-Color: black;
    opacity: 0.5;
    position: fixed;
    margin-Left: 0%;
    margin-Top: 0%;
    height: 100%;
  }

  .open_lead_box{
    width: 98%;
    z-Index: 6;
    position: fixed;
    margin-Left: 1%;
    margin-Top: 1%;
    height: 92%;
    background: #FFFFFF;
    border-Radius: 30px;
  }

  .open_lead_box_subdiv{
      height: auto;
      width: 99%;
      font-Style: normal;
      font-Weight: 500;
      font-Size: 55px;
    }



  .shown_image_box{
    width: 96%;
    z-Index: 9;
    position: fixed;
    margin-Left: 2%;
    margin-Top: 2%;
    height: 82%;
    background: #FFFFFF;
    border-Radius: 30px;
  }

  .search_category_box{
    height:250px;
    border:0;
    position:absolute;
    border-Width:0;
   overflow-Y:auto;
   box-Shadow: 0px 4px 20px rgba(199, 199, 199, 0.6);
   border-Radius:6px 0px 0px 6px;
   margin-Top:2%;
   opacity:100%;
   z-Index:13;
   width:91%;
   line-Height:14px;
   background-Color:white;
   margin-Left:3%;
 }


  .grey_button {
    height: 60px;
    font-size: 15px;
    width: 110px;
    padding: 0%;
    color: black;

  }

  .clicked_button {
    color: white;
    height: 60px;
    width: 130px;
    padding: 2%;
    font-size: 15px;
    border-radius: 8px;
    background-color: #0A6338;
  }

  .visit_company_page{
      color: #0A6338;
      cursor: pointer;
      text-Decoration: underline;
      width: auto;
      margin-left: 2%;
  }

  .update_company_info{
    font-Size: 16px;
    height: 25px;
    font-Weight: 600;
    margin-Top: 0%;
    width: auto;
    margin-left: 2%;
    color: #0A6338;
  }

  .box_main{
    width:98%;
    margin-Left: 1%;
    height:auto;
    margin-Top:2%;
    border-Radius:18px;
    background-Color:white;
    box-Shadow: 0px 3px 30px #0000001A;
    padding-Bottom:13px;
  }

  .box_main_image{
    width:98%;
    margin-Left: 1%;
    height:auto;
    margin-Top:2%;
    border-Radius:18px;
    background-Color:white;
    box-Shadow: 0px 3px 30px #0000001A;
    padding-Bottom:20%;
  }


  .multiple-image-upload_main {
    width: 98%;
    padding: 3%;
    margin-left: 1%;
    height: auto;
    margin-top: 2%;
    border-radius: 18px;
    background-color: white;
    box-shadow: 0 3px 30px #0000001a;
    padding-Bottom:38%;

  }


  .company_info_group{
    height: 40px;
    width: 100%;
    margin-Left: 0%;
    text-Align: left;
  }

  .company_info_group_comp_info{
    margin-Top: 0%;
    height: auto;
    text-Align: left;
    margin-Left: 0%;
    width: 100%;
  }


  .box_main_info{
      margin-Left: 4%;
      font-Size: 18px;
      padding-Top: 0%;
      color: #0A6338;
    }


     .box_main_info_em{
       height:auto;
       font-Size:13px;
        margin-Left:4%;
        display:flex;
         color:black;
     }

     .input_header {
       margin-left: 4%;
       margin-top: 1%;
       height: 20px;
       color: black;
     }

     .show_chosen_image {
       height: 35px;
       border-radius: 8px;
       width: 35px;
       margin-left: 5%;
       margin-top: 2%;
       display: flex;
       object-fit: contain;
       position: absolute;
       background-color: white;
     }


     .register_button {
       color: white;
       margin-left: 25%;
       margin-top: 2%;
       font-size: 18px;
       height: 60px;
       width: 40%;
       border-style: None;
       border-radius: 8px;
       background-color: grey;
     }

     .register_button_clicked {
       color: white;
       margin-left: 25%;
       margin-top: 4%;
       font-size: 18px;
       height: 60px;
       width: 40%;
       border-style: None;
       border-radius: 8px;
       background-color: #0A6338;
     }

     .label_size {
       width: 150px;
       font-weight: 600;
       font-size: 16px;
       text-align: left;

     }

     .box_description {
       height: auto;
       padding-bottom: 0.2%;
       margin-top: 1%;
       width: 95%;
       font-size: 20px;
     }

     .lead_width_namn {
       text-align: center;

       max-width: 200px;
       min-width: 200px;
       height: 90px;
       font-size: 15px;
       padding-top: 0.5%;
     }

     .lead_width {
       max-width: 120px;
       min-width: 120px;
       height: 90px;
       font-size: 15px;
       padding-top: 0.5%;
       text-align: left;
     }


     .main_comp {
       display: flex;
       width: auto;
       margin-left: 0%;
       margin-top: 1%;
       font-size: 16px
     }


     .shown_image_box_image{
       height: 90%;
       margin-Top: 4%;
       width: 80%;
     }



}



  .shown_image_box_dark{
        width: 100%;
        z-Index: 4;
        background-Color: black;
        opacity: 0.5;
        position: fixed;
        margin-Left: 0%;
        margin-Top: 0%;
        height: 100%;
    }





.grey_button_small {
  z-index: 13;
  height: 33px;
  font-size: 13px;
  width: 120px;
  padding-top: 2%;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  color: black;
  text-align: center;
  background: linear-gradient(180deg, #ffffff 0%, #f5f5f5 100%);
}
.multiple-image-upload__header {
  margin-left: 4%;
  display: flex;
  font-size: 20px;
  padding-top: 0;
}

.multiple-image-upload__headline {
  width: 270px;
}



.lead_width_loc {
  /* width: 200px; */
  max-width: 190px;
  min-width: 190px;
  white-space: normal;
  word-wrap: break-word;
  height: auto;
  font-size: 18px;
  padding-top: 0.5%;
  padding-left: 1%;

  /* border-left: 1px solid black; */
  text-align: left;
  /*padding-left: 2%; */
  /* border: 0.5px Solid; */
}

.lead_width_header_loc {
  max-width: 190px;
  min-width: 190px;
  padding-left: 1%;
  /* width: 280px; */
  height: 20px;
  font-size: 21px;
  /* text-align: left; */
  /* border-left: 1px solid black; */
  /* border: 0.1px Solid grey; */
}

.flow {
  height: auto;
  width: 92%;
  display: flex;
  margin-left: 4.2%;
  margin-top: 0%;
  flex-wrap: wrap;
  padding-bottom: 120px;
}


.boxes {
  /* height:30px;
  margin-left: 5%;
   display:flex;
   overflow-x: auto;
   scrollbar-width:thin; */
  font-size: 25px;
  text-align: left;
  height: 20px;
  padding-bottom: 0.2%;
  margin-top: 2%;
  /* margin-left: 2%; */
  display: flex;
  margin-top: 1%;

  width: 90%;
}


.box_flow_key {
  /* background: linear-gradient(180deg, #459AFB 0%, #2875CE 100%); */
  border-radius: 50px;
  background-color: #0A6338;
  /* max-width: auto; */
  /* min-width: 100px; */
  /* flex-direction: row; */
  height: auto;
  color: white;
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin-left: 1%;
  font-size: 13px;
  /* width: 120%; */
  /* float: left;*/
  display: inline-block;
  flex: none;
  box-sizing: border-box;
}


.title {
  width: 85%;
  height: 50px;
  margin-top: 3%;
  font-size: 25px;
  margin-left: 4%;
  color: #0A6338;
}

.box_flow {
  margin-left: 1%;
  color: grey;
  height: auto;
  /* overflow-x: auto; */
  /* width: auto; */
  display: inline-block;
  flex: none;
  box-sizing: border-box;
  font-size: 16px;
  font-size: 15px;
}

.box_flow_images {
  margin-left: 1%;
  color: grey;
  height: 90px;
  width: 98px;
  /* overflow-x: auto; */
  /* width: auto; */
  display: flex;
}


body {
  font-family: sans-serif;
}


.input_field_pass {
  border: 2px solid #59419b6f;
  color: black;
  margin-top: 0%;
  width: 88%;
  margin-left: 2%;
  border-radius: 8px;
  height: 29px;
}

.lead_width_left {
  /* width: 200px; */
  max-width: 190px;
  min-width: 190px;
white-space: normal;
word-wrap: break-word;
  height: auto;
  font-size: 18px;
  padding-top: 0.5%;
 padding-left: 1%;
  /* border-left: 1px solid black; */
  text-align: left;
  /*padding-left: 2%; */
  /* border: 0.5px Solid; */
}


.input_field {
  border: 2px solid #59419b6f;
  color: black;
  margin-top: 1%;
  width: 88%;
  margin-left: 4%;
  border-radius: 8px;

  height: 29px;
}


.input_field_l {
  border: 2px solid #59419b6f;
  color: black;
  margin-top: 1%;
  width: 88%;
  margin-left: 4%;
  border-radius: 8px;
  white-space: pre-wrap;
  height: 300px;
  resize: none;
}

.input_field_po {
  border: 2px solid #59419b6f;
  color: black;
  margin-top: 1%;
  width: 88%;
  margin-left: 4%;
  border-radius: 8px;
  white-space: pre-wrap;
  height: 180px;
  resize: none;
}


.register_header {
  width: 99%;
  text-align: left;
  font-size: 16px;
  margin-top: 2%;
  padding: 3%;
  margin-left: 5%;
  height: 110px;
}

.Global_div {
  height: 2550px;
  width: 100%;
  margin-left: 0%;
  /* position: absolute; */
  display: flex;
}

.header_1 {
  text-align: center;
  height: 112px;
  width: 100%;
  display: flex;
  background-color: #33d075;
  /* position:absolute; */
}

input {
  display: flex;
  width: 100%;
}

h2 {
  display: flex;
  text-decoration: underline;
}

.addphoto_camera {
  height: 40px;
  width: 65px;
  margin-left: -23%;
  margin-top: 0%;
  position: absolute;
  cursor: pointer;
  /* background-color: #33D075; */
  color: black;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0%;
}

.show_chosen_image_first {
  height: 70px;
  border-radius: 8px;
  width: 70px;
  display: flex;
  object-fit: contain;
  /* position: absolute; */
  background-color: white;
}

.open_gear_box_outside {
  position: fixed;
  height: 1200px;
  opacity: 0.5;
  background: black;
  z-index: 2;
  width: 100%;
  left: 0px;
  top: 0px;
}

.checkbox_new {
  margin-left: 1.5%;
  margin-top: 0%;
  /* border-radius:50%; */
  border-style: None;
  height: 18px;
  width: 18px;
  /* background-color: white; */
  /* position: absolute; */
  /* accent-color:white; */
}

.checkbox_filled {
  margin-left: 1.5%;
  margin-top: 0%;
  color: white;
  /* background-color: #33D075; */
  border-radius: 50%;
  border-style: None;
  max-height: 18px;
  max-width: 18px;
  accent-color: #0A6338;
}

.search_ruta_input {
  margin-left: 4%;
  width: 88%;
  height: 24px;
  box-shadow: 0px 3px 12px #00000012;
  margin-top: 2%;
  border-radius: 8px;
  z-index: 4;
  border: 2px solid #59419b6f;
}

.search_ruta_input__ {
  width: 70%;
  height: 90%;
  border-radius: 12px;
  color: #0A6338;
  border-color: #0A6338;
  border: 2px solid #59419b6f;
  color: black;
  padding-left: 2%;
  border-style: none;
}

.box_flow_key {
  /* background: linear-gradient(180deg, #459AFB 0%, #2875CE 100%); */
  border-radius: 50px;
  background-color: #0A6338;
  /* max-width: auto; */
  /* min-width: 100px; */
  /* flex-direction: row; */
  height: auto;
  color: white;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  margin-left: 1%;
  margin-top: 1%;
  font-size: 13px;
  /* width: 120%; */
  /* float: left;*/
  display: inline-block;
  flex: none;
  box-sizing: border-box;
}




</style>
