<template lang="html">
  <div :style="{zIndex:13,height:'100%'}">
    <div v-if="open_status_var" :style="{marginTop:'3%',marginLeft:'15%',zIndex:13, height:'100%'}">
      <div class="grey_button" @click="choose_status(status.status)" v-for="(status,idx) in status_list" :key="idx">
        {{status.status}}
      </div>
    </div>

  </div>
</template>

<script>
import {bus} from '../main'

export default {
  props:["open_status_var","lead_object"],
  data:function(){
    return{
      open_status_var_local:false,
      status_list:[]
    }
  },
  methods:{

    choose_status( new_status){

      bus.$emit('choose-status',{'lead_object':this.lead_object, 'new_status':new_status})
    }
  },
  mounted(){
    this.open_status_var_local = this.open_status_var

    this.status_list=[
      {'i':1,'id':0, 'status':'Ny'},
      {'i':2,'id':1, 'status':'Kontaktad'},
      {'i':3,'id':2, 'status':'Väntar svar'},
      {'i':4,'id':3, 'status':'Avbruten'},
      {'i':5,'id':4, 'status':'Genomfört'},
    ]



  }
}
</script>

<style lang="css" scoped>
.grey_button{
z-index: 13;
  height:33px;
  text-align: center;
  font-size: 13px;
    width: 120px;
  padding-top: 2%;
  border-Radius: 8px ;
  border: 1px solid #C7C7C7;
  color:black;
  background: linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%)
}
</style>
